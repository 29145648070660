import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { encode } from "../helpers";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Notification from "../components/notification";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;

  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

const FormItemWrapper = styled.div`
  margin: 0 0 1.5rem;
`;

const styles = () => ({
  icon: {
    marginLeft: 10
  },
  textField: {
    width: 500
  }
});

class ContactPage extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    open: false,
    status: ""
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => this.setState({ open: false });

  handleSubmit = e => {
    e.preventDefault();
    if (
      this.state.name !== "" &&
      this.state.email !== "" &&
      this.state.message !== ""
    ) {
      const form = e.target;
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          name: this.state.name,
          email: this.state.email,
          message: this.state.message
        })
      })
        .then(() => this.setState({ open: true, status: "success" }))
        .catch(() => this.setState({ open: true, status: "error" }));
    } else {
      this.setState({ open: true, status: "warning" });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout>
        <SEO title="Xabadu" keywords={[`gatsby`, `application`, `react`]} />
        <PageWrapper>
          <Notification
            handleClose={this.handleClose}
            open={this.state.open}
            type={this.state.status}
          />
          <h1>Need to get in touch or just wanna say hi? <span role="img" aria-label="wave hand emoji">👋</span></h1>
          <form
            name="contact"
            method="post"
            action="#"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <FormItemWrapper hidden className="hidden">
              <label htmlFor="bot-field">Don't fill this!</label>
              <input name="bot-field" onChange={this.handleChange} />
            </FormItemWrapper>
            <FormItemWrapper>
              <TextField
                name="name"
                label="Name"
                className={classes.textField}
                value={this.state.name}
                onChange={this.handleChange}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <TextField
                name="email"
                label="Email"
                className={classes.textField}
                value={this.state.email}
                onChange={this.handleChange}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <TextField
                name="message"
                label="Message"
                className={classes.textField}
                value={this.state.message}
                onChange={this.handleChange}
                multiline
                rowsMax="6"
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                Send
              </Button>
            </FormItemWrapper>
          </form>
        </PageWrapper>
      </Layout>
    );
  }
}

export default withStyles(styles)(ContactPage);
