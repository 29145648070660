import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { COLORS } from "../../constants";

const styles = () => ({
  error: {
    backgroundColor: COLORS.RED.ERROR
  },
  success: {
    backgroundColor: COLORS.GREEN.SUCCESS
  },
  warning: {
    backgroundColor: COLORS.YELLOW.WARNING
  }
});

const MESSAGES = {
  error: "Oh noes! Something didn't work 😔. Do you mind trying again?",
  success: "Thanks for your message! I'll get back to you as soon as I can ✌️",
  warning: "Do you mind filling everything before sending? 🙄"
};

const MySnackbarContent = props => {
  const { classes, className, message, onClose, variant, ...other } = props;

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} size="xs" color={COLORS.WHITE} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

const Notification = props => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    open={props.open}
    autoHideDuration={6000}
    onClose={props.handleClose}
  >
    <MySnackbarContentWrapper
      onClose={props.handleClose}
      variant={props.type}
      message={MESSAGES[props.type]}
    />
  </Snackbar>
);

export default Notification;
